import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const About = () => (
  <Layout>
    <Seo title="About" />
    <div style={{textAlign: 'center'}}>
    <h1
      className="text-glow" 
      style={{
        fontSize: '36pt',
        marginBottom: '40px',
        marginTop: '-20px'
      }}>
        Our Values
      </h1>
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: '30px', flexWrap: 'wrap'}}>
        <div style={{marginLeft: '30px', marginRight: '30px'}}>
          <StaticImage
            src="../images/professionalism.png"
            quality={100}
            width={200}
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt="app screnshots"
            style={{marginBottom: '35px'}}
          />
          <div style={{flexDirection: 'column'}}>
            <h2 className='text-glow values-title' style={{marginBottom: '12px'}}>
              Professionalism
            </h2>
            <h5 className='text-glow values-subtitle' style={{color: '#cccccc'}}>
              5+ years of experience
            </h5>
          </div>
        </div>
        <div style={{marginLeft: '30px', marginRight: '30px'}}>
          <StaticImage
            src="../images/creativity.png"
            quality={100}
            width={200}
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt="app screnshots"
            style={{marginBottom: '35px'}}
          />
          <div fle>
            <h2 className='text-glow values-title' style={{ marginBottom: '12px'}}>
              Creativity
            </h2>
            <h5 className='text-glow values-subtitle' style={{ color: '#cccccc'}}>
              Innovating 24/7
            </h5>
          </div>
        </div>
        <div style={{marginLeft: '30px', marginRight: '30px'}}>
          <StaticImage
            src="../images/passion.png"
            quality={100}
            width={200}
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt="app screnshots"
            style={{marginBottom: '35px'}}
          />
          <h2 className='text-glow values-title' style={{marginBottom:'12px'}}>
            Passion
          </h2>
          <h5 className='text-glow values-subtitle' style={{color: '#cccccc'}}>
            We love making apps!
          </h5>
        </div>
      </div>
      <h2 className="text-glow" style={{fontSize: '30px'}}>
        We are committed to making great products
      </h2>
      <h2 className="app-card-text-glow" style={{fontSize: '30px', color: '#cccccc', marginBottom: '66px'}}>
        And can't wait to hear about your app
      </h2>
      <h3 className="text-glow" style={{fontSize: '60px', marginBottom: '50px', textAlign: 'left'}}>
      Making an insanely great product has a lot to do with the process of making the product.
      </h3>
      <h3 className="app-card-text-glow" style={{fontSize: '76px', textAlign: 'right', marginBottom: '100px'}}>
        Steve Jobs
      </h3>
    </div>
  </Layout>
)

export default About
